<template>
  <div>
    <Finder type="full" />
  </div>
</template>
<script>
import Finder from './finder'

export default {
  components: {
    Finder,
  },
}
</script>
<style lang="scss" module>
</style>
